define('ember-math-helpers/helpers/gcd', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.gcd = gcd;


  /**
   * Returns the greatest positive integer that divides each of two integers
   */
  function gcd([_a = 0, _b = 0]) {
    const a = Math.abs(_a);
    const b = Math.abs(_b);

    if (a === 0) {
      return b;
    }

    if (b === 0) {
      return a;
    }

    return gcd([b, a % b]);
  }

  exports.default = Ember.Helper.helper(gcd);
});