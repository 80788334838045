define('ivy-videojs/components/ivy-videojs-player', ['exports', 'ember', 'videojs'], function (exports, _ember, _videojs) {

  /**
   * Renders a `video` element, and applies a video.js player to it. Also
   * provides some methods for binding properties to the player, and for proxying
   * player events to actions.
   *
   * This is the lower-level component that `ivy-videojs` uses internally.
   *
   * @class
   * @extends Ember.Component
   */
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'video',

    classNames: ['video-js'],

    mergedProperties: ['playerEvents'],

    /**
     * The set of video.js player events (and associated actions) to be set up
     * inside `didInsertElement`. If you need to respond to custom video.js
     * player events, such as those emitted by a plugin, you should do so by
     * calling `sendActionOnPlayerEvent` inside your `ready` handler.
     *
     * ```javascript
     * import Ember from 'ember';
     *
     * export default Ember.Controller.extend({
     *   actions: {
     *     ready(player, component) {
     *       component.sendActionOnPlayerEvent(player, 'actionName', 'eventName');
     *     }
     *   }
     * });
     * ```
     *
     * The above would send the `actionName` action in response to an `eventName`
     * event from the player.
     *
     * @property playerEvents
     * @type Object
     * @private
     */
    playerEvents: {
      abort: 'abort',
      canplay: 'canplay',
      canplaythrough: 'canplaythrough',
      durationchange: 'durationchange',
      emptied: 'emptied',
      ended: 'ended',
      error: 'error',
      loadeddata: 'loadeddata',
      loadedmetadata: 'loadedmetadata',
      loadstart: 'loadstart',
      pause: 'pause',
      play: 'play',
      playing: 'playing',
      progress: 'progress',
      ratechange: 'ratechange',
      resize: 'resize',
      seeked: 'seeked',
      seeking: 'seeking',
      stalled: 'stalled',
      suspend: 'suspend',
      timeupdate: 'timeupdate',
      useractive: 'useractive',
      userinactive: 'userinactive',
      volumechange: 'volumechange',
      waiting: 'waiting'
    },

    /**
     * Sets up a (one-way) binding for a property from this component to the
     * video.js player. If the property is changed, it will be propagated to the
     * video.js player (via `setPlayerProperty`).
     *
     * @method bindPropertyToPlayer
     * @param {Player} player the video.js player instance
     * @param {String} property the component property to bind
     * @param {String} playerProperty the video.js player property
     * @see {#setPlayerProperty}
     */
    bindPropertyToPlayer: function bindPropertyToPlayer(player, property) {
      var playerProperty = arguments.length <= 2 || arguments[2] === undefined ? property : arguments[2];
      return (function () {
        var observer = function observer() {
          this.setPlayerProperty(player, playerProperty, this.get(property));
        };

        var scheduledObserver = function scheduledObserver() {
          _ember['default'].run.scheduleOnce('render', this, observer);
        };

        this._addPlayerObserver(property, this, scheduledObserver);

        // Invoke the observer once to set the initial value on the player.
        observer.call(this);
      }).apply(this, arguments);
    },

    /**
     * Initializes the video.js player, sets up event listeners defined in
     * `playerEvents`, and sends the `ready` action.
     *
     * @method didInsertElement
     */
    didInsertElement: function didInsertElement() {
      var _this = this;

      var player = (0, _videojs['default'])(this.get('element'), this.get('setup'));

      player.ready(function () {
        // Set up a handler to automatically dispose the player on teardown.
        _this.one('willDestroyElement', function () {
          player.dispose();
        });

        // Set up event listeners defined in `playerEvents`.
        var playerEvents = _this.get('playerEvents');
        if (playerEvents) {
          for (var key in playerEvents) {
            if (!playerEvents.hasOwnProperty(key)) {
              continue;
            }
            _this.sendActionOnPlayerEvent(player, key, playerEvents[key]);
          }
        }

        // Let the outside world know that we're ready.
        _this.sendAction('ready', player, _this);
      });
    },

    /**
     * Sets up a listener that sends an action on a video.js player event.
     *
     * @method sendActionOnPlayerEvent
     * @param {Player} player the video.js player instance
     * @param {String} action the action name to be sent
     * @param {String} playerEvent the player event name to listen for
     */
    sendActionOnPlayerEvent: function sendActionOnPlayerEvent(player, action) {
      var playerEvent = arguments.length <= 2 || arguments[2] === undefined ? action : arguments[2];
      return (function () {
        var _this2 = this;

        var listenerFunction = function listenerFunction() {
          for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
            args[_key] = arguments[_key];
          }

          _this2.sendAction.apply(_this2, [action, player, _this2].concat(args));
        };

        this._onPlayerEvent(player, playerEvent, listenerFunction);
      }).apply(this, arguments);
    },

    /**
     * Sets the value of a property on a video.js player. If the property is
     * already equal to the given value, no change is made.
     *
     * @method setPlayerProperty
     * @param {Player} player the video.js player instance
     * @param {String} playerProperty the name of the property to set
     * @param {Object} value the value to set
     */
    setPlayerProperty: function setPlayerProperty(player, playerProperty, value) {
      var propertyMethod = player[playerProperty];
      if (propertyMethod) {
        var previousValue = propertyMethod.call(player);
        if (previousValue !== value) {
          propertyMethod.call(player, value);
        }
      }
    },

    _addPlayerObserver: function _addPlayerObserver(property, target, observer) {
      if (this.isDestroying) {
        return;
      }

      this.addObserver(property, target, observer);

      this.one('willDestroyElement', this, function () {
        this.removeObserver(property, target, observer);
      });
    },

    _onPlayerEvent: function _onPlayerEvent(player, eventName, listenerFunction) {
      player.on(eventName, listenerFunction);
    }
  });
});