define('ivy-videojs/components/ivy-videojs', ['exports', 'ember', 'ivy-videojs/templates/components/ivy-videojs'], function (exports, _ember, _ivyVideojsTemplatesComponentsIvyVideojs) {

  function proxyAction(action) {
    return function () {
      for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }

      this.sendAction.apply(this, [action].concat(args));
    };
  }

  /**
   * Provides a simplified interface to the underlying `ivy-videojs-source`
   * component, which should (hopefully) be sufficient for most use cases.
   *
   * @class
   * @extends Ember.Component
   */
  exports['default'] = _ember['default'].Component.extend({
    concatenatedProperties: ['playerAttributeBindings'],

    /**
     * Properties which will be bound to the video.js player.
     *
     * @property playerAttributeBindings
     * @type Array
     * @private
     */
    playerAttributeBindings: ['autoplay', 'controls', 'fluid', 'language', 'loop', 'muted', 'playbackRate', 'poster', 'src', 'volume'],

    actions: {
      abort: proxyAction('abort'),
      canplay: proxyAction('canplay'),
      canplaythrough: proxyAction('canplaythrough'),
      durationchange: proxyAction('durationchange'),
      emptied: proxyAction('emptied'),
      ended: proxyAction('ended'),
      error: proxyAction('error'),
      loadeddata: proxyAction('loadeddata'),
      loadedmetadata: proxyAction('loadedmetadata'),
      loadstart: proxyAction('loadstart'),
      pause: proxyAction('pause'),
      play: proxyAction('play'),
      playing: proxyAction('playing'),
      progress: proxyAction('progress'),
      ratechange: proxyAction('ratechange'),
      resize: proxyAction('resize'),
      seeked: proxyAction('seeked'),
      seeking: proxyAction('seeking'),
      stalled: proxyAction('stalled'),
      suspend: proxyAction('suspend'),
      timeupdate: proxyAction('timeupdate'),
      useractive: proxyAction('useractive'),
      userinactive: proxyAction('userinactive'),
      volumechange: proxyAction('volumechange'),
      waiting: proxyAction('waiting'),

      ready: function ready(player, component) {
        this.setupPlayerAttributeBindings(player, component);

        for (var _len2 = arguments.length, args = Array(_len2 > 2 ? _len2 - 2 : 0), _key2 = 2; _key2 < _len2; _key2++) {
          args[_key2 - 2] = arguments[_key2];
        }

        this.sendAction.apply(this, ['ready', player, component].concat(args));
      }
    },

    layout: _ivyVideojsTemplatesComponentsIvyVideojs['default'],

    /**
     * Set up property bindings for each property defined in
     * `playerAttributeBindings`.
     *
     * @method setupPlayerAttributeBindings
     */
    setupPlayerAttributeBindings: function setupPlayerAttributeBindings(player, component) {
      this.get('playerAttributeBindings').forEach(function (property) {
        component.bindPropertyToPlayer(player, property);
      });
    }
  });
});